import http from '@/api/http';
import { message } from 'antd';
const origin = process.env.NODE_ENV === 'development' ? 'http://47.116.209.51' : window.location.origin;

const global = {
  origin,
  get(url, params, fn){
    return http.get(url, {params}).then(res => {
      return this.getData(res, fn)
    }).catch(err => {
      console.log('catch', url, err)
    })
  },
  post(url, params, fn){
    return http.post(url, params).then(res => {
      return this.getData(res, fn)
    }).catch(err => {
      console.log('catch', url, err)
    })
  },
  getData(res, fn){
    fn && fn(res.data, res)
    return res
  },
  download(url){
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.click();
    a.remove();
  },
  xunleiUrl(url){
    return `thunder://${btoa(encodeURIComponent(`AA${url}ZZ`))}`;
  },
  copy(str,msg = '复制成功'){
    const input = document.createElement('input');
    input.setAttribute('value', str);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    if(msg){message.success(msg)}
  }
}

export default global