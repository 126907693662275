import React from "react";
import { HashRouter } from 'react-router-dom';
import RouterView from "./router";

function App() {
  return <HashRouter>
    <div className="content">
        <RouterView />
    </div>
  </HashRouter>;
}

export default App;
