import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

const routes = [
{path: '/', component: () => <Navigate to="/file" />},
{path: '/index', name:'index', component: lazy(() => import(/* webpackChunkName:"index" */'../views/index'))},
{path: '/file/:projectNo/:parentId', name:'file',  component: lazy(() => import(/* webpackChunkName:"file" */'../views/file'))},
{path: '/fileIframe/:projectNo?', name:'fileIframe',  component: lazy(() => import(/* webpackChunkName:"file" */'../views/fileIframe'))},
// {
//     path: '*',
//     component: () => {
//         return <Navigate to={{
//             pathname: '/',
//             search: '?from=404'
//         }} />;
//     }
// }
];
export default routes;