import axios from "axios";
import { message } from 'antd';
import fullLoading from '@/components/fullLoading';

const http = axios.create({
    // baseURL: '/api',
    timeout: 60000
});

// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // fullLoading.show();
    return config;
}, function (error) {
    // 对请求错误做些什么
    // fullLoading.hide();
    return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(response => {
    // 对响应数据做点什么
    // fullLoading.hide();
    if(response.data.code !== 0 && response.data.message){
        message.error(response.data.message);
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    // fullLoading.hide();
    message.error(error.response.data.error || '当前网络繁忙，请您稍后再试~');
    return Promise.reject(error);
});
export default http;